import React from 'react'
import SubscriptionAction from "../stores/Subscription/SubscriptionAction";
import FormatVariations from "../helpers/FormatVariations";
import CartAction from "../stores/Cart/CartAction";
import FormatCartSubscriptions from "../helpers/FormatCartSubscriptions";
import DisplayPrice from "../helpers/DisplayPrice";
import GetPricesPerRink from "../helpers/GetPircesPerRink";


const Subscription = (props) => {

    const subscriptionData = props.subscription[props.subscription.show]


    if (props.subscription.show && subscriptionData) {

        const formattedTimes = FormatVariations(subscriptionData.variations, props.cart.contents)
        const cartSubscriptions = FormatCartSubscriptions(props.cart.contents.items, props.subscription)

        let title = props.subscription.show === 'training' ? 'Trainingsabonnement' : 'Wedstrijdabonnement'
        const count = cartSubscriptions[props.subscription.show].length + 1
        let price = 0

        const prices = GetPricesPerRink(props.user.category.acf, props.rink.activeRink)



        if (prices) {

            if (props.subscription.show === 'training') {

                if (count === 1) {
                    price = DisplayPrice(prices.prijs_eerste_trainingsabonnement)
                } else {
                    price = DisplayPrice(prices.prijs_opvolgend_trainingsabonnement)

                }

            } else {

                if (count === 1) {
                    price = DisplayPrice(prices.prijs_eerste_wedstrijdabonnement)
                } else {
                    price = DisplayPrice(prices.prijs_opvolgend_wedstrijdabonnement)
                }

            }
        }

        return (
            <div className="c-subscription__options__single c-subscription__options__single--dark">
                <header>
                    <div><span>{count}e {title}</span>
                        <div>{price}</div>
                    </div>
                    <button onClick={() => props.dispatch(SubscriptionAction.displaySubscription(false))}>Abonnement
                        verwijderen
                    </button>
                </header>
                <div className="c-subscription__options__single__body"><h3>Selecteer een dag en tijdstip:</h3>

                    {Object.keys(formattedTimes).map((dayKey, index) => {

                        const day = formattedTimes[dayKey]

                        return (
                            <div key={index} className="c-subscription__options__single__body__row"><span>{dayKey}</span>
                                <div>
                                    {Object.values(day).map((time, index) => {

                                        return <button key={index}
                                            onClick={() => clickItem(time)}>{time.time}</button>

                                    })}

                                </div>
                            </div>
                        );

                    })}

                </div>
            </div>
        )
    } else return null

    function clickItem(time) {
        props.dispatch(CartAction.addSubscription(time.data.variation_id)).then(() => {
            props.dispatch(SubscriptionAction.displaySubscription(false))
        })
    }

}

export default Subscription
