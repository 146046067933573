import CartAction from "./CartAction";

export default class CartReducer {

    static initialState = {
        contents: false,
        loading: false
    };

    static reducer(state = CartReducer.initialState, action) {
        switch (action.type) {

            case CartAction.ADD_ITEM:

                return {
                    ...state,
                    loading: true
                }


            case CartAction.ADD_ITEM + '_FINISHED':

                return {
                    ...state,
                    contents: action.payload,
                    loading: false
                }

            case CartAction.REMOVE_ITEM:

                return {
                    ...state,
                    loading: true
                }

            case CartAction.REMOVE_ITEM + '_FINISHED':

                return {
                    ...state,
                    contents: action.payload,
                    loading: false
                }

            case CartAction.CLEAR_CART:

                return {
                    ...state,
                    loading: true
                }

            case CartAction.CLEAR_CART + '_FINISHED':

                return {
                    ...state,
                    contents: action.payload,
                    loading: false
                }


            default:
                return state;
        }
    }
}

