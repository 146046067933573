import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import rootStore from './stores/rootStore';
import './styles/Main.scss';


(async (window) => {
    const initialState = {};
    const store = rootStore(initialState);
    const rootEl = document.getElementById('root');
    const render = (Component, el) => {
        ReactDOM.render(
            <Provider store={store}>
                <Component dispatch={store.dispatch}/>
            </Provider>,
            el
        );
    };
    render(App, rootEl);

})(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

