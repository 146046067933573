import React from 'react'
import Header from "./components/Header";
import Intro from "./components/Intro";
import SubscriptionTotals from "./components/SubscriptionTotals";

import Step from "./components/Step";
import Modal from "./components/Modal";

function App() {
    return (
        <main>
            <Intro/>
            <div className="c-subscription">
                <Header/>
                <div className="c-subscription__body">
                    <Step/>
                    <Modal/>
                    <SubscriptionTotals/>
                </div>
            </div>
        </main>
    )
}

export default App;
