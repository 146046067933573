import ActionUtility from '../../helpers/ActionUtility'
import UserEffect from "./UserEffect";

export default class UserAction {

    static SET_INPUT = 'UserAction.SET_INPUT';
    static CALCULATE_AGE = 'UserAction.CALCULATE_AGE';
    static API_REQUEST_AGE_CATEGORY = 'UserAction.API_REQUEST_AGE_CATEGORY';
    static API_REQUEST_AGE_CATEGORY_LIST = 'UserAction.API_REQUEST_AGE_CATEGORY_LIST';


    static setInput(type, value) {
        return async (dispatch, getState) => {

            const values = {
                'type': type,
                'value': value
            }

            await ActionUtility.createThunkEffect(dispatch, UserAction.SET_INPUT, UserEffect.setInput, values);
        };
    }

    static calculateAge() {
        return async (dispatch, getState) => {
            const {inputs} = getState().user

            const dateTimes = {
                visible: new Date(inputs['Jaar'].selected, inputs['Maand'].selected - 1, inputs['Dag'].selected),
                underwater: new Date(inputs['Jaar'].selected, inputs['Maand'].selected - 1, inputs['Dag'].selected),
            }


            await ActionUtility.createThunkEffect(dispatch, UserAction.CALCULATE_AGE, UserEffect.calculateAge, dateTimes);
        };
    }

    static apiRequestAgeCategory() {

        return async (dispatch, getState) => {

            const {age} = getState().user


            await ActionUtility.createThunkEffect(dispatch, UserAction.API_REQUEST_AGE_CATEGORY, UserEffect.apiRequestAgeCategory, age.underwater);

        };

    }

    static apiRequestAgeCategoryList() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.API_REQUEST_AGE_CATEGORY_LIST, UserEffect.apiRequestAgeCategoryList);

        };

    }

}