export default class CartEffect {

    static async addItem(url, dispatch) {
        return fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                variation: {
                    "attribute_colours": "Red",
                    "attribute_pa_size": "2x-large"
                }
            })
        }).then((response) => {
            return response.json()
        }).then((response) => {

            return response

        }).catch(function (error) {
        })
    }

    static async removeItem(url) {
        return fetch(url, {
            method: 'delete',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            return response

        }).catch(function (error) {
        })
    }

    static async clearCart(url) {
        return fetch(url, {
            method: 'post',
            headers: {
                'Accept': 'application/json'
            },

        }).then((response) => {
            return response.json()
        }).then((response) => {

            return false

        }).catch(function (error) {
        })
    }


    static async setTime(value, dispatch) {
        return value
    }
}

