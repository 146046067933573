import React from "react";

const Intro = (props) => {


    return (
        <div className="c-intro">
            <img src="https://stg-zaanstreek.nl/app/themes/stgzaanstreek/images/logo.jpg" alt="" style={{width: '70rem', filter: 'invert(1) brightness(50%) sepia(100%) saturate(10000%)'}}/>
            <h1>Aanvraagformulier ijsabonnement</h1>
        </div>
    )

}

export default Intro
