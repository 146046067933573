import AppAction from "./AppAction";

export default class AppReducer {

    static initialState = {
        activeStep: 1,
        modal: false
    };

    static reducer(state = AppReducer.initialState, action) {
        switch (action.type) {

            case AppAction.NEXT_STEP + '_FINISHED':
            case AppAction.PREV_STEP + '_FINISHED':
            case AppAction.CHANGE_STEP + '_FINISHED':

                return {
                    ...state,
                    activeStep: action.payload
                }

            case AppAction.DISPLAY_MODAL + '_FINISHED':

                return {
                    ...state,
                    modal: action.payload
                }

            default:
                return state;
        }
    }
}

