import SubscriptionAction from "./SubscriptionAction";

export default class SubscriptionReducer {

    static initialState = {
        training: null,
        competition: null,
        show: false
    };

    static reducer(state = SubscriptionReducer.initialState, action) {
        switch (action.type) {

            case SubscriptionAction.DISPLAY_SUBSCRIPTION + '_FINISHED':

                return {
                    ...state,
                    show: action.payload
                }

            case SubscriptionAction.API_REQUEST_COMPETITION_SUBSCRIPTION + '_FINISHED':

                return {
                    ...state,
                    competition: action.payload
                }

            case SubscriptionAction.API_REQUEST_TRAINING_SUBSCRIPTION + '_FINISHED':

                return {
                    ...state,
                    training: action.payload
                }

            default:
                return state;
        }
    }
}

