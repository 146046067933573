import ActionUtility from '../../helpers/ActionUtility'
import AppEffect from "./AppEffect";

export default class AppAction {

    static NEXT_STEP = 'AppAction.NEXT_STEP';
    static PREV_STEP = 'AppAction.PREV_STEP';
    static CHANGE_STEP = 'AppAction.CHANGE_STEP';
    static DISPLAY_MODAL = 'AppAction.DISPLAY_MODAL';

    static nextStep() {
        return async (dispatch, getState) => {

            const value = getState().app.activeStep + 1

            await ActionUtility.createThunkEffect(dispatch, AppAction.NEXT_STEP, AppEffect.step, value);
        };
    }

    static prevStep() {
        return async (dispatch, getState) => {

            const value = getState().app.activeStep - 1

            await ActionUtility.createThunkEffect(dispatch, AppAction.PREV_STEP, AppEffect.step, value);
        };
    }

    static changeStep(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.CHANGE_STEP, AppEffect.step, value);
        };
    }

    static displayModal(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.DISPLAY_MODAL, AppEffect.displayModal, value);
        };
    }
}