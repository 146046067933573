import React from "react";
import RinkAction from "../stores/Rink/RinkAction";


const Rink = (props) => {

    const className = props.active ? 'c-subscription__location__options__single isActive' : 'c-subscription__location__options__single'

    return (
        <button className={className} onClick={() => onClick(props.term_id)}>
            <figure>
                <svg width="17px" height="15px" viewBox="0 0 11 9" version="1.1">
                    <g id="Abonnement-kiezen" stroke="none" strokeWidth="1" fill="none"
                       fillRule="evenodd">
                        <polygon id="Path-2" fill="#ffffff" fillRule="nonzero"
                                 points="9.24466984 0.344655552 10.7553302 1.65534445 4.39100148 8.99067693 0.340981624 5.44202347 1.65901838 3.93776979 4.197 6.161"/>
                    </g>
                </svg>
            </figure>
            <span>{props.name}</span>
        </button>
    )

    function onClick(id) {
        props.dispatch(RinkAction.setActiveRink(id))
    }

}

export default Rink