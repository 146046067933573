import React from 'react'
import {connect} from "react-redux";
import AgeModal from "../modals/AgeModal";
import SubscriptionModal from "../modals/SubscriptionModal";

const mapStateToProps = (state) => (
    {
        app: state.app,
    }
)

const Modal = (props) => {

    const {app} = props
    const {modal} = app

    switch (modal) {
        case('ageCategories'):
            return <AgeModal/>
        case('subscription'):
            return <SubscriptionModal/>
        default:
            console.log(modal)
            return null
    }

}

export default connect(mapStateToProps)(Modal)