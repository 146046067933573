import ActionUtility from '../../helpers/ActionUtility'
import CartEffect from "./CartEffect";

const FormatUrl = (url, contents, ageCategory) => {

    if (contents && contents.cart_key) {
        return url + '?age-category=' + ageCategory + '&cart_key=' + contents.cart_key + '&cocart-load-cart=' + contents.cart_key + '&'
    } else return url + '?age-category=' + ageCategory + '&'

}

const baseCartUrl = process.env.REACT_APP_API_URL + '/cocart/v2/cart'

export default class CartAction {
    static ADD_ITEM = 'CartAction.ADD_ITEM';
    static REMOVE_ITEM = 'CartAction.REMOVE_ITEM';
    static CLEAR_CART = 'CartAction.CLEAR_CART';

    static addItem(id) {
        return async (dispatch, getState) => {

            let url = FormatUrl(baseCartUrl + '/add-item/', getState().cart.contents, getState().user.category.ID) + 'id=' + id
            await ActionUtility.createThunkEffect(dispatch, CartAction.ADD_ITEM, CartEffect.addItem, url);
        };
    }

    static addSubscription(id) {
        return async (dispatch, getState) => {

            let url = FormatUrl(baseCartUrl + '/add-item/', getState().cart.contents, getState().user.category.ID) + 'id=' + id + '&type=' + getState().subscription.show + '&nr=1'
            await ActionUtility.createThunkEffect(dispatch, CartAction.ADD_ITEM, CartEffect.addItem, url);
        };
    }

    static removeItem(item_key) {
        return async (dispatch, getState) => {
            let url = baseCartUrl + '/item/' + item_key + '?cart_key=' + getState().cart.contents.cart_key + '&age-category=' + getState().user.category.ID
            await ActionUtility.createThunkEffect(dispatch, CartAction.REMOVE_ITEM, CartEffect.removeItem, url);
        };
    }

    static clearCart() {
        return async (dispatch, getState) => {
            if (getState().cart.contents) {
                const cartKeyUrlParam = '?cart_key=' + getState().cart.contents.cart_key;
                const clearCartUrlBase = baseCartUrl + '/clear';
                const clearCartUrl = clearCartUrlBase + cartKeyUrlParam;

                await ActionUtility.createThunkEffect(dispatch, CartAction.CLEAR_CART, CartEffect.clearCart, clearCartUrl);
            }
        };
    }

    static calculateTotals() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, CartAction.calculateTotals, CartEffect.calculateTotals, getState().cart.contents);
        };
    }

}