import React from 'react'
import FormatCartSubscriptions from "../helpers/FormatCartSubscriptions";
import DisplayPrice from "../helpers/DisplayPrice";
import CartAction from "../stores/Cart/CartAction";
import NextStep from "./NextStep";

const SubscriptionsInCart = (props) => {

    if (props.cart.contents && props.subscription) {

        const subscriptions = FormatCartSubscriptions(props.cart.contents.items, props.subscription)

        if (subscriptions) {

            let competitionCount = 0
            let trainingCount = 0

            return (
                <div>
                    {subscriptions.competition.map((subscription, index) => {
                        competitionCount++

                        const time = subscription.meta.variation['Dag | Tijden'];

                        return (
                            <div key={index}
                                 className="c-subscription__options__single c-subscription__options__single--light">
                                <header>
                                    <div><span>{competitionCount}e Wedstrijdabonnement</span>
                                        <div>{DisplayPrice(subscription.price / 100)}</div>
                                    </div>
                                    <button
                                        onClick={() => props.dispatch(CartAction.removeItem(subscription.item_key))}>Abonnement
                                        verwijderen
                                    </button>
                                </header>
                                <div className="c-subscription__options__single__body"><h3>Gekozen dag en tijdstip</h3>
                                    <div
                                        className="c-subscription__options__single__body__row c-subscription__options__single__body__row__chosen">
                                        <div>
                                            <button>{time}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>)

                    })}

                    {subscriptions.training.map((subscription, index) => {
                        trainingCount++

                        const time = subscription.meta.variation['Dag | Tijden'];

                        return (
                            <div key={index}
                                 className="c-subscription__options__single c-subscription__options__single--light">
                                <header>
                                    <div><span>{trainingCount}e Trainingsabonnement</span>
                                        <div>{DisplayPrice(subscription.price / 100)}</div>
                                    </div>
                                    <button
                                        onClick={() => props.dispatch(CartAction.removeItem(subscription.item_key))}>Abonnement
                                        verwijderen
                                    </button>
                                </header>
                                <div className="c-subscription__options__single__body"><h3>Gekozen dag en tijdstip</h3>
                                    <div
                                        className="c-subscription__options__single__body__row c-subscription__options__single__body__row__chosen">
                                        <div>
                                            <button>{time}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>)

                    })}

                    <NextStep text={'Volgende stap'}/>


                </div>
            )
        }
    }

    return null

}

export default SubscriptionsInCart
