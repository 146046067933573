const WizardItems = () => {

    return [
        {
            label: 'Geboortedatum',
            editStep: 1,
        },
        {
            label: 'Abonnementen',
            editStep: 3,
        },
        {
            label: "Extra's",
            editStep: 4,
        },
        {
            label: "Gegevens & betalen",
            editStep: false,
        }
    ]

}

export default WizardItems
