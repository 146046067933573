const FormatCartSubscriptions = (cartItems, subscriptionVariations) => {

    let subscriptions = {
        training: [],
        competition: [],
        total: 0,
        totalExtra: 0
    }

    if (cartItems) {
        cartItems.forEach((cartItem) => {

            const isCompetition = subscriptionVariations.competition.variations.some(competitionVariation => competitionVariation.variation_id === cartItem.id)

            if (isCompetition) {

                subscriptions = {
                    ...subscriptions,
                    total: subscriptions.total + 1
                }

                subscriptions.competition.push(cartItem)

            } else {

                if (subscriptionVariations.training) {

                    const isTraining = subscriptionVariations.training.variations.some(competitionVariation => competitionVariation.variation_id === cartItem.id)

                    if (isTraining) {
                        subscriptions = {
                            ...subscriptions,
                            total: subscriptions.total + 1
                        }

                        subscriptions.training.push(cartItem)
                    }else{
                        subscriptions = {
                            ...subscriptions,
                            totalExtra: subscriptions.totalExtra + 1
                        }

                    }
                }
            }


        })
    }

    return subscriptions;

}

export default FormatCartSubscriptions
