import UserAction from "./UserAction";
import moment from 'moment'

export default class AppReducer {

    static initialState = {
        inputs: {
            'Dag': {
                'selected': null,
                'range': {
                    'start': 1,
                    'end': 31
                }
            },
            'Maand': {
                'selected': null,
                'range': {
                    'start': 1,
                    'end': 12
                }
            },
            'Jaar': {
                'selected': null,
                'range': {
                    'start': 1900,
                    'end': moment().format('YYYY')
                }

            }
        },
        age: null,
        category: null,
        categoryList: null,

    };

    static reducer(state = AppReducer.initialState, action) {
        switch (action.type) {

            case UserAction.SET_INPUT + '_FINISHED':

                return {
                    ...state,
                    inputs: {
                        ...state.inputs,
                        [action.payload.type]: {
                            ...state.inputs[action.payload.type],
                            selected: action.payload.value
                        }
                    }
                }

            case UserAction.CALCULATE_AGE + '_FINISHED':

                return {
                    ...state,
                    age: action.payload
                }

            case UserAction.API_REQUEST_AGE_CATEGORY:

                return {
                    ...state,
                    category: 'loading'
                }

            case UserAction.API_REQUEST_AGE_CATEGORY + '_FINISHED':

                return {
                    ...state,
                    category: action.payload
                }


            case UserAction.API_REQUEST_AGE_CATEGORY_LIST + '_FINISHED':

                return {
                    ...state,
                    categoryList: action.payload
                }



            default:
                return state;
        }
    }
}

