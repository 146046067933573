import moment from 'moment'

export default class UserEffect {

    static async setInput(values, dispatch) {
        return values
    }

    static async calculateAge(dob, dispatch) {

        Object.keys(dob).forEach((dateKey) => {

            const date = dob[dateKey]

            dob[dateKey] = moment(moment().set('month', 5).set('date', 30).endOf('day').toDate()).diff(date, 'years');;


        })

        return dob

    }

    static async apiRequestAgeCategory(age) {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/age-category?age=' + age, {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

    static async apiRequestAgeCategoryList() {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/age-category-list', {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

}

