import React from 'react'
import AppAction from "../stores/App/AppAction";
import {connect} from 'react-redux'

const mapStateToProps = (state) => ({
    user: state.user
})

const AgeModal = (props) => {

    if (props.user.categoryList) {
        return (
            <div className="c-modal" style={{opacity: 1, pointerEvents: 'all'}}>
                <div className="c-modal__background"/>
                <div className="c-modal__content">
                    <header className="c-modal__header"><h5>Waarom vragen wij je geboortedatum?</h5>
                        <button
                            onClick={() => props.dispatch(AppAction.displayModal(false))}
                            onKeyDown={() => props.dispatch(AppAction.displayModal(false))}
                        >
                            Sluiten
                        </button>
                    </header>
                    <div className="c-modal__body">
                        <table>
                            <tbody>

                            <tr>
                                <th>Leeftijd</th>
                                <th>Categorie</th>
                            </tr>

                            {props.user.categoryList.sort((a, b) => parseInt(a.acf.leeftijd.vanaf) > parseInt(b.acf.leeftijd.vanaf) ? 1 : -1).map((item, index) => {

                                return (
                                    <tr key={index}>
                                        <td>{item.acf.leeftijd.vanaf}</td>
                                        <td>{item.post_name}</td>
                                    </tr>
                                )

                            })}

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    } else return null

}

export default connect(mapStateToProps)(AgeModal)