import React from "react";
import {connect} from "react-redux";
import WizardItems from "../static/WizardItems";
import AppAction from "../stores/App/AppAction";

const mapStateToProps = (state) => (
    {app: state.app}
)

const Header = (props) => {

    const {app} = props
    const {activeStep} = app

    const wizardItems = WizardItems()
    let wizardCount = 0;

    return (
        <nav className="c-subscription__header">

            {wizardItems && wizardItems.map((wizardItem, index) => {
                wizardCount++;

                return (
                    <div className={getWizardItemClassName(wizardItem.editStep)} key={index}>

                        <img src="images/check-green.svg" alt=""/>

                        <span>
                                <span className="desktop">{wizardCount}.</span> {wizardItem.label}<span
                            className="mobile">({wizardCount}/{wizardItems.length})</span>
                            </span>

                        <button
                            onClick={() => props.dispatch(AppAction.changeStep(wizardItem.editStep))}>Wijzigen
                        </button>
                    </div>
                )

            })}


        </nav>
    )

    function getWizardItemClassName(editStep) {
        const complete = editStep && editStep < activeStep;
        const active = editStep && editStep === activeStep;

        let className = 'c-subscription__header__single'

        if (complete) {
            className += ' isComplete'
        }

        if (active) {
            className += ' isActive'
        }

        return className
    }

}

export default connect(mapStateToProps)(Header)