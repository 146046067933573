import React from 'react'
import {connect} from "react-redux";
import RinkAction from "../stores/Rink/RinkAction";
import Rink from "../components/Rink";
import {batch} from 'react-redux'
import SubscriptionAction from "../stores/Subscription/SubscriptionAction";
import NextStep from "../components/NextStep";
import CartAction from "../stores/Cart/CartAction";
import ExtraAction from "../stores/Extra/ExtraAction";

const mapStateToProps = (state) => ({
    rink: state.rink
})

class Rinks extends React.Component {

    componentDidMount() {
        this.props.dispatch(RinkAction.apiRequestRinks())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.rink.activeRink && prevProps.rink.activeRink !== this.props.rink.activeRink) {

            batch(() => {
                this.props.dispatch(CartAction.clearCart())
                this.props.dispatch(SubscriptionAction.apiRequestTrainingSubscription())
                this.props.dispatch(SubscriptionAction.apiRequestCompetitionSubscription())
                this.props.dispatch(ExtraAction.apiRequestExtras())
            })

        }
    }

    render() {
        const {rink} = this.props
        const {rinks, activeRink} = rink

        if (rinks) {

            if (rinks === 'loading') {

                return <p>Wordt geladen...</p>

            } else return (
                <div className="c-subscription__location">

                    <div className="c-subscription__text">
                        <h2>Voor welke ijsbaan wilt u een abonnement?</h2>
                        <p>Ook voor de andere ijsbaan een abonnement kiezen? Vul dan opnieuw het formulier in.</p>
                    </div>

                    <div className="c-subscription__location__options">

                        {rinks.map((rink, index) => {
                            return (
                                <Rink active={rink.term_id === activeRink}
                                      dispatch={this.props.dispatch} {...rink}
                                      key={index}
                                />
                            )
                        })}

                    </div>

                    {activeRink && <NextStep text={'Volgende stap'}/>}

                </div>
            )

        } else return null
    }

}

export default connect(mapStateToProps)(Rinks)