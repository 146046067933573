export default class SubscriptionEffect {



    static async apiRequestCompetitionSubscription(rinkId) {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/competition-subscription?rink-id=' + rinkId, {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

    static async apiRequestTrainingSubscription(rinkId) {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/training-subscription?rink-id=' + rinkId, {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

    static async displaySubscription(type) {
        return type
    }

}
