export default class AppEffect {

    static async step(value, dispatch) {
        return value
    }

    static async displayModal(value, dispatch) {
        return value
    }

}

