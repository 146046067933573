import ExtraAction from "./ExtraAction";

export default class ExtraReducer {

    static initialState = {
        list: false
    };

    static reducer(state = ExtraReducer.initialState, action) {
        switch (action.type) {

            case ExtraAction.API_REQUEST_EXTRAS + '_FINISHED':

                return {
                    ...state,
                    list: action.payload
                }

            default:
                return state;
        }
    }
}

