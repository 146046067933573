import RinkAction from "./RinkAction";

export default class RinkReducer {

    static initialState = {
        rinks: null,
        activeRink: null,
    };

    static reducer(state = RinkReducer.initialState, action) {
        switch (action.type) {

            case RinkAction.API_REQUEST_RINKS:

                return {
                    ...state,
                    rinks: 'loading'
                }


            case RinkAction.API_REQUEST_RINKS + '_FINISHED':

                return {
                    ...state,
                    rinks: action.payload
                }

            case RinkAction.SET_ACTIVE_RINK + '_FINISHED':

                return {
                    ...state,
                    activeRink: action.payload
                }

            default:
                return state;
        }
    }
}

