const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR'
})

const DisplayPrice = (number) => {

    return formatter.format(parseFloat(number))

}

export default DisplayPrice