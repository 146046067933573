import React from "react";
import {connect} from "react-redux";
import MonthByNumber from "../helpers/MonthByNumber";
import UserAction from "../stores/User/UserAction";
import AgeCategory from "../components/AgeCategory";
import NextStep from "../components/NextStep";
import AppAction from "../stores/App/AppAction";

const mapStateToProps = (state) => (
    {
        user: state.user
    }
)

class DateOfBirth extends React.Component {
    componentDidMount() {
        this.props.dispatch(UserAction.apiRequestAgeCategoryList())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        const {inputs} = this.props.user

        if (prevProps.user.inputs !== inputs) {
            if (inputs['Dag'].selected && inputs['Maand'].selected && inputs['Jaar'].selected) {

                this.props.dispatch(UserAction.calculateAge()).then(() => {
                    this.props.dispatch(UserAction.apiRequestAgeCategory())
                })

            }
        }
    }


    render() {

        const {user} = this.props
        const {inputs, age, category} = user

        return (
            <div className="c-subscription__dob">
                <div className="c-subscription__dob__input">
                    <label htmlFor="">Wat is je geboortedatum?
                        <button onClick={() => this.props.dispatch(AppAction.displayModal('ageCategories'))}
                                className="c-subscription__info-btn c-subscription__info-btn--blue">
                            <span>i</span></button></label>

                    {inputs &&

                        <div className="c-subscription__dob__input__wrapper">

                            {Object.keys(inputs).map((inputKey) => {

                                const input = inputs[inputKey]
                                let numbers = this._getSelectRanges(input.range)

                                if (inputKey === 'Jaar') {
                                    numbers = numbers.sort((function (a, b) {
                                        return b - a;
                                    }))

                                }

                                const defaultValue = input.selected ? input.selected : "DEFAULT";

                                return (
                                    <div className="c-subscription__select" key={inputKey}>

                                        <select defaultValue={defaultValue}
                                                onChange={(e) => this.props.dispatch(UserAction.setInput(inputKey, e.target.value))}>

                                            <option value="DEFAULT" disabled>{inputKey}</option>

                                            {numbers.map((number, index) => {

                                                let label = number

                                                if (inputKey === 'Maand') {
                                                    label = MonthByNumber(number)
                                                }

                                                return <option key={index} value={number}>{label}</option>
                                            })}
                                        </select>

                                        <img src="images/arrow-down.svg" alt=""/>

                                    </div>
                                )
                            })}

                        </div>

                    }

                </div>

                <div className="c-subscription__dob__result">
                    {!!age &&
                        <div className="c-subscription__dob__result__single">
                            <span>{age.visible} jaar</span>
                        </div>
                    }

                    <AgeCategory category={category}/>


                </div>

                {(this.props.user.category && this.props.user.category !== 'loading') &&
                    <NextStep text={'Volgende stap'}/>}

            </div>
        )
    }

    _getSelectRanges = (range) => {

        const ranges = [];

        for (let i = range.start; i <= range.end; i++) {
            ranges.push(i);
        }

        return ranges.sort((function (a, b) {
            return a - b;
        }))

    }

}

export default connect(mapStateToProps)(DateOfBirth)