export default class RinkEffect {

    static async apiRequestRinks() {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/ice-rinks', {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

    static setActiveRink(id) {
        return id
    }
}

