import ActionUtility from '../../helpers/ActionUtility'
import RinkEffect from "./RinkEffect";

export default class RinkAction {
    static API_REQUEST_RINKS = 'RinkAction.API_REQUEST_RINKS';
    static SET_ACTIVE_RINK = 'RinkAction.SET_ACTIVE_RINK';

    static apiRequestRinks() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RinkAction.API_REQUEST_RINKS, RinkEffect.apiRequestRinks);
        };
    }

    static setActiveRink(id) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RinkAction.SET_ACTIVE_RINK, RinkEffect.setActiveRink, id);
        };
    }
}