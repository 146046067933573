import ActionUtility from '../../helpers/ActionUtility'
import SubscriptionEffect from "./SubscriptionEffect";

export default class SubscriptionAction {
    static API_REQUEST_COMPETITION_SUBSCRIPTION = 'SubscriptionAction.API_REQUEST_COMPETITION_SUBSCRIPTION';
    static API_REQUEST_TRAINING_SUBSCRIPTION = 'SubscriptionAction.API_REQUEST_TRAINING_SUBSCRIPTION';
    static DISPLAY_SUBSCRIPTION = 'SubscriptionAction.DISPLAY_SUBSCRIPTION';



    static apiRequestCompetitionSubscription() {
        return async (dispatch, getState) => {

            const {activeRink} = getState().rink

            await ActionUtility.createThunkEffect(dispatch, SubscriptionAction.API_REQUEST_COMPETITION_SUBSCRIPTION, SubscriptionEffect.apiRequestCompetitionSubscription, activeRink);
        };
    }

    static apiRequestTrainingSubscription() {
        return async (dispatch, getState) => {

            const {activeRink} = getState().rink

            await ActionUtility.createThunkEffect(dispatch, SubscriptionAction.API_REQUEST_TRAINING_SUBSCRIPTION, SubscriptionEffect.apiRequestTrainingSubscription, activeRink);
        };
    }

    static displaySubscription(types) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, SubscriptionAction.DISPLAY_SUBSCRIPTION, SubscriptionEffect.displaySubscription, types);
        };
    }

}