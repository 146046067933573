import React from 'react'
import {connect} from "react-redux";
import Subscription from "../components/Subscription";
import SubscriptionAction from "../stores/Subscription/SubscriptionAction";
import AppAction from "../stores/App/AppAction";
import SubscriptionsInCart from "../components/SubscriptionsInCart";
import FormatCartSubscriptions from "../helpers/FormatCartSubscriptions";

const mapStateToProps = (state) => ({
    subscription: state.subscription,
    cart: state.cart,
    user: state.user,
    rink: state.rink
})

class Subscriptions extends React.Component {

    render() {

        const rink = this.props.rink.rinks.filter(rink => rink.term_id === this.props.rink.activeRink)[0]

        let competitionButtonClass = ''
        let trainingButtonClass = ''


        if (this.props.cart.contents && this.props.subscription) {
            const subscriptions = FormatCartSubscriptions(this.props.cart.contents.items, this.props.subscription)

            if (subscriptions.training.length) {
                competitionButtonClass = 'btn-is-disabled'
            }

            if (subscriptions.competition.length) {
                trainingButtonClass = 'btn-is-disabled'

            }
        }


        return (
            <div className={'c-subscription__options loading-' + this.props.cart.loading}>
                <header className="c-subscription__options__header">
                    <button className="info-btn"
                            onClick={() => this.props.dispatch(AppAction.displayModal('subscription'))}>
                        <div className="c-subscription__info-btn c-subscription__info-btn--blue"><span>i</span></div>
                        <span>Prijsopbouw</span></button>
                    <hr/>
                    <div className="c-subscription__options__header__location"><span>{rink.name}</span>
                        <button onClick={() => this.props.dispatch(AppAction.changeStep(2))}>Wijzigen</button>
                    </div>
                </header>
                <div className="c-subscription__text"><h2>Voeg één of meerdere abonnementen toe:</h2></div>

                {!this.props.subscription.show &&
                <div className="c-subscription__options__buttons">
                    <button className={competitionButtonClass}
                            onClick={() => this.props.dispatch(SubscriptionAction.displaySubscription('competition'))}>
                        <span>+ Wedstrijdabonnement toevoegen</span>
                    </button>
                    <button className={trainingButtonClass}
                            onClick={() => this.props.dispatch(SubscriptionAction.displaySubscription('training'))}>
                        <span>+ Trainingsabonnement toevoegen</span>
                    </button>
                </div>
                }

                <Subscription {...this.props}/>

                <SubscriptionsInCart {...this.props}/>


            </div>
        )
    }

}

export default connect(mapStateToProps)(Subscriptions)
