const MonthByNumber = (number) => {

    const months = {
        1: 'Januari',
        2: 'Februari',
        3: 'Maart',
        4: 'April',
        5: 'Mei',
        6: 'Juni',
        7: 'Juli',
        8: 'Augustus',
        9: 'September',
        10: 'Oktober',
        11: 'November',
        12: 'December'
    }

    return months[number]

}

export default MonthByNumber