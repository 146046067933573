import React from 'react'
import {connect} from "react-redux";
import DateOfBirth from "../steps/DateOfBirth";
import Options from "../steps/Subscriptions";
import Extra from "../steps/Extra";
import Location from "../steps/Rinks";

const mapStateToProps = (state) => (
    {
        app: state.app
    }
)

const Step = (props) => {

    const {app} = props
    const {activeStep} = app

    switch (activeStep) {
        case(1):
            return <DateOfBirth/>
        case(2):
            return <Location/>
        case(3):
            return <Options/>
        case(4):
            return <Extra/>
        default:
            return null
    }

}

export default connect(mapStateToProps)(Step)