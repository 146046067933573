export default class ExtraEffect {

    static async apiRequestExtras(rinkId) {
        return fetch(process.env.REACT_APP_API_URL + '/subscription/v1/extras?rink-id=' + rinkId, {
            method: 'get',
            headers: {
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
        })
    }

}