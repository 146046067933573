import React from "react";
import {connect} from "react-redux";
import AppAction from "../stores/App/AppAction";

const mapStateToProps = (state) => ({
    app: state.app
})

const NextStep = (props) => {
    return (
        <div className="c-subscription__next">
            <button  onClick={() => props.dispatch(AppAction.nextStep())}>{props.text}</button>
        </div>
    )
}

export default connect(mapStateToProps)(NextStep)