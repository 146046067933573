import ActionUtility from '../../helpers/ActionUtility'
import ExtraEffect from "./ExtraEffect";

export default class ExtraAction {
    static API_REQUEST_EXTRAS = 'ExtraAction.API_REQUEST_EXTRAS';


    static apiRequestExtras() {

        return async (dispatch, getState) => {

            const {activeRink} = getState().rink

            await ActionUtility.createThunkEffect(dispatch, ExtraAction.API_REQUEST_EXTRAS, ExtraEffect.apiRequestExtras, activeRink);
        };

    }


}