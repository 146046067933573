import React from 'react'
import {connect} from "react-redux";
import DisplayPrice from "../helpers/DisplayPrice";
import CartAction from "../stores/Cart/CartAction";

const mapStateToProps = (state) => ({
    extra: state.extra,
    cart: state.cart,
    user: state.user

})

class Extra extends React.Component {


    render() {
        if (this.props.extra.list) {
            return (
                <div className={'c-subscription__extra  loading-' + this.props.cart.loading}>
                    {this.props.extra.list.map((extraItem, index) => {

                        const cartItem = this.props.cart.contents.items.filter(item => item.id === extraItem.productId)[0]
                        const buttonClass = 'c-subscription__buttons__single'
                        let buttonDeclineClass = buttonClass + ' isActive'
                        let buttonAcceptClass = buttonClass

                        if (cartItem) {
                            buttonDeclineClass = buttonClass
                            buttonAcceptClass = buttonClass + ' isActive'
                        }

                        return (
                            <div key={index} className="c-subscription__extra__single"><span
                                className="c-subscription__extra__single__text">Wilt u '{extraItem.title}' aanschaffen </span>
                                <div className="c-subscription__buttons">

                                    {cartItem
                                        ? <button className={buttonDeclineClass}
                                                  onClick={() => this.props.dispatch(CartAction.removeItem(cartItem.item_key))}>
                                            Nee
                                        </button>
                                        : <button className={buttonDeclineClass}>Nee</button>
                                    }

                                    <button className={buttonAcceptClass}
                                            onClick={() => this.props.dispatch(CartAction.addItem(extraItem.productId))}>Ja <small>({DisplayPrice(extraItem.price)})</small>
                                    </button>
                                </div>
                            </div>
                        )

                    })}

                    <div className="c-subscription__next">
                        <a href={process.env.REACT_APP_CHECKOUT_URL + '/?cocart-load-cart=' + this.props.cart.contents.cart_key + '&birth-date=' + this.props.user.inputs['Dag'].selected + '-' + this.props.user.inputs['Maand'].selected + '-'  + this.props.user.inputs['Jaar'].selected } style={{padding: '1rem', background: '#e32b28', color: '#fff', textDecoration: 'none', borderRadius: '.6rem'}}>Naar
                            gegevens en betalen</a>
                    </div>

                </div>
            );
        } else return null
    }

}

export default connect(mapStateToProps)(Extra)