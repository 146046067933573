import React from "react";
import {connect} from 'react-redux'
import DisplayPrice from "../helpers/DisplayPrice";
import FormatCartSubscriptions from "../helpers/FormatCartSubscriptions";

const mapStateToProps = (state) => ({
    cart: state.cart,
    subscription: state.subscription
})

class SubscriptionTotals extends React.Component {


    render() {

        if (this.props.cart.contents && this.props.subscription) {

            const subscriptions = FormatCartSubscriptions(this.props.cart.contents.items, this.props.subscription)

            console.log(subscriptions)

            return (
                <footer className="c-subscription__totals">
                    <span>{subscriptions.total} abonnementen {!!subscriptions.totalExtra && <span>, {subscriptions.totalExtra} Extra(s)</span>}</span>
                    <span>Totaal: {DisplayPrice(this.props.cart.contents.totals.total / 100)}</span>
                </footer>
            )
        } else return null
    }
}

export default connect(mapStateToProps)(SubscriptionTotals)