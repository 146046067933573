import {combineReducers} from 'redux';
import AppReducer from "./App/AppReducer";
import UserReducer from "./User/UserReducer";
import RinkReducer from "./Rink/RinkReducer";
import CartReducer from "./Cart/CartReducer";
import SubscriptionReducer from "./Subscription/SubscriptionReducer";
import ExtraReducer from "./Extra/ExtraReducer";


const rootReducer = () => {

    const reducerMap = {
        app: AppReducer.reducer,
        user: UserReducer.reducer,
        rink: RinkReducer.reducer,
        cart: CartReducer.reducer,
        subscription: SubscriptionReducer.reducer,
        extra: ExtraReducer.reducer

    };

    return combineReducers(reducerMap);
};

export default rootReducer