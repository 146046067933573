const FormatVariations = (variations, cartContents) => {

    const times = {}

    if (cartContents && cartContents.items) {
        cartContents.items.forEach((cartItem) => {
            if (cartItem.id && cartItem.meta.variation && cartItem.meta.variation['Dag | Tijden']) {
                variations = variations.filter(variation => variation.variation_id !== cartItem.id)
            }
        })
    }

    variations.forEach((dateTime) => {

        const data = dateTime.attributes.attribute_pa_dagtijd.split('-')

        if (!times[data[0]]) {
            times[data[0]] = []
        }

        times[data[0]].push({
            data: dateTime,
            time: insertAt(data[1], ':', 2) + ' - ' + (insertAt(data[2], ':', 2))
        })

    })

    const sorter = {
        "maandag": 1,
        "dinsdag": 2,
        "woensdag": 3,
        "donderdag": 4,
        "vrijdag": 5,
        "zaterdag": 6,
        "zondag": 7
    }

    return Object.keys(times).sort(function sortByDay(a, b) {

        let day1 = a.toLowerCase();
        let day2 = b.toLowerCase();
        return sorter[day1] - sorter[day2];
    }).reduce(
        (obj, key) => {
            obj[key] = times[key];
            return obj;
        },
        {}
    );

}

const insertAt = (str, sub, pos) => `${str.slice(0, pos)}${sub}${str.slice(pos)}`;

export default FormatVariations