import React from 'react'
import AppAction from "../stores/App/AppAction";
import {connect} from 'react-redux'
import DisplayPrice from "../helpers/DisplayPrice";
import GetPricesPerRink from "../helpers/GetPircesPerRink";

const mapStateToProps = (state) => ({
    user: state.user,
    rink: state.rink
})

const SubscriptionModal = (props) => {

    return (
        <div className="c-modal" style={{opacity: 1, pointerEvents: 'all'}}>
            <div className="c-modal__background"/>
            <div className="c-modal__content">
                <header className="c-modal__header">
                    <h5>Prijsopbouw abonnementen</h5>
                    <button
                        onClick={() => props.dispatch(AppAction.displayModal(false))}
                        onKeyDown={() => props.dispatch(AppAction.displayModal(false))}
                    >
                        Sluiten
                    </button>
                </header>
                <div className="c-modal__body"><p>De prijsopbouw van de eerst gekozen abonnementen ziet u hieronder.
                    Opvolgende abonnementen worden met een korting berekend.</p><h5
                    className="c-modal__body__subject">Reguliere prijsopbouw</h5>
                    <table>
                        <tbody>
                        <tr>
                            <th>Leeftijd</th>
                            <th>Categorie</th>
                            <th>Trainingsabbo.</th>
                            <th>Wedstrijdsabbo.</th>
                        </tr>

                        {props.user.categoryList.sort((a, b) => parseInt(a.acf.leeftijd.vanaf) > parseInt(b.acf.leeftijd.vanaf) ? 1 : -1).map((item, index) => {

                            const prices = GetPricesPerRink(item.acf, props.rink.activeRink)

                            if (prices) {
                                return (
                                    <tr key={index}>
                                        <td>{item.acf.leeftijd.vanaf}-{item.acf.leeftijd.tot}</td>
                                        <td>{item.post_title}</td>
                                        <td>{DisplayPrice(prices.prijs_eerste_trainingsabonnement)}</td>
                                        <td>{DisplayPrice(prices.prijs_eerste_wedstrijdabonnement)}</td>
                                    </tr>
                                )
                            } else return null
                        })}

                        </tbody>
                    </table>
                    <h5 className="c-modal__body__subject">Opvolgende abonnementen</h5>
                    <table>
                        <tbody>
                        <tr>
                            <th>Categorie</th>
                            <th>Opvolgend trainingsabbo.</th>
                            <th>Opvolgend wedstrijdsabbo.</th>
                        </tr>

                        {props.user.categoryList.sort((a, b) => parseInt(a.acf.leeftijd.vanaf) > parseInt(b.acf.leeftijd.vanaf) ? 1 : -1).map((item, index) => {

                            const prices = GetPricesPerRink(item.acf, props.rink.activeRink)

                            return (
                                <tr key={index}>
                                    <td>{item.post_title}</td>
                                    <td>{DisplayPrice(prices.prijs_opvolgend_trainingsabonnement)}</td>
                                    <td>{DisplayPrice(prices.prijs_opvolgend_wedstrijdabonnement)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

export default connect(mapStateToProps)(SubscriptionModal)