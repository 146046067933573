import React from 'react'

const AgeCategory = (props) => {

    if (props.category) {

        if (props.category === 'loading') {
            return (
                <div className="c-subscription__dob__result__single">
                    <span>Categorie: <strong>Wordt geladen...</strong></span>
                </div>
            )
        } else {
            return (
                <div className="c-subscription__dob__result__single">
                    <span>Categorie: <strong>{props.category.post_title}</strong></span>
                </div>
            )
        }

    } else return null
}

export default AgeCategory